

function consoleText(words, id, colors, conso) {
	debugger;
	if (colors === undefined) colors = ['rgba(0,121,192,1)'];
	console.log(id);
	var visible = true;
	var con = document.getElementById(conso);
	var letterCount = 1;
	var x = 1;
	var waiting = false;
	var target = document.getElementById(id);
	console.log(target);
	target.setAttribute('style', 'color:' + colors[0])
	window.setInterval(function () {

		if (letterCount === 0 && waiting === false) {
			waiting = true;
			target.innerHTML = words[0].substring(0, letterCount)
			window.setTimeout(function () {
				var usedColor = colors.shift();
				colors.push(usedColor);
				var usedWord = words.shift();
				words.push(usedWord);
				x = 1;
				target.setAttribute('style', 'color:' + colors[0])
				letterCount += x;
				waiting = false;
			}, 120)
		} else if (letterCount === words[0].length + 1 && waiting === false) {
			waiting = true;
			window.setTimeout(function () {
				x = -1;
				letterCount += x;
				waiting = false;
			}, 1200)
		} else if (waiting === false) {
			target.innerHTML = words[0].substring(0, letterCount)
			letterCount += x;
		}
	}, 50)
	window.setInterval(function () {
		if (visible === true) {
			con.className = 'console-underscore hidden'
			visible = false;

		} else {
			con.className = 'console-underscore hidden'
			visible = true;
		}
	}, 200)
}


$(document).ready(function () {
	if ((typeof planPricing !== 'undefined') && (typeof countryCode !== 'undefined')) {
		$("input.pricing-change-btn[type='radio']").click(function () {
			changePricingPlanByDuration(planPricing, countryCode)
		});
		changePricingPlanByDuration(planPricing, countryCode)
	}
	$(document).on('click', '.confirmUnsubscribe', function () {
		var url = document.location.href;
		var paramArray = splitIntoParams(url)
		var authKey = paramArray.filter(function (val) {
			if (val.param == "authKey") return true
			return false
		})
		$.ajax({
			method: 'POST',
			url: '/unsubscribeConfirmed',
			data: {
				authKey: authKey,
				confirmedUnsubscribe: true
			},
			success: function (res) {
				alert('You have been unsubscribed successfully');
				window.location = "/"
			},
			error: function (err) {
				alert('Unsubscrition Failed, try again later');
				window.location = "/"
			}
		})
	})

});


// for showing running count 

$(function () {
	function count($this) {
		var current = parseInt($this.html(), 10);
		if (current !== $this.data('count')) {
			$this.html(current + 1);
			setTimeout(function () {
				count($this)
			}, 50);
		}
	}

	function two($this) {
		var current = parseInt($this.html(), 10);
		if (current !== $this.data('count')) {
			$this.html(current + 50);
			setTimeout(function () {
				two($this)
			}, 50);
		}
	}

	function three($this) {
		var current = parseInt($this.html(), 10);
		if (current !== $this.data('count')) {
			$this.html(++current);
			setTimeout(function () {
				three($this)
			}, 50);
		}
	}

	function four($this) {
		var current = parseInt($this.html(), 10);
		if (current !== $this.data('count')) {
			$this.html(current + 150);
			setTimeout(function () {
				four($this)
			}, 50);
		}
	}

	function five($this) {
		var current = parseInt($this.html(), 10);
		if (current !== $this.data('count')) {
			$this.html(current + 1000);
			setTimeout(function () {
				five($this)
			}, 50);
		}
	}

	function six($this) {
		var current = parseInt($this.html(), 10);
		if (current !== $this.data('count')) {
			$this.html(current + 200);
			setTimeout(function () {
				six($this)
			}, 50);
		}
	}

	$(".icon-detail span.counter.home-counter-one").each(function () {
		$(this).data('count', parseInt($(this).html(), 10));
		$(this).html('0');
		count($(this));
	});
	$(".icon-detail span.counter.home-counter-two").each(function () {
		$(this).data('count', parseInt($(this).html(), 10));
		$(this).html('0');
		two($(this));
	});
	$(".icon-detail span.counter.home-counter-three").each(function () {
		$(this).data('count', parseInt($(this).html(), 10));
		$(this).html('0');
		three($(this));
	});
	$(".icon-detail span.counter.home-counter-four").each(function () {
		$(this).data('count', parseInt($(this).html(), 10));
		$(this).html('0');
		four($(this));
	});
	$(".icon-detail span.counter.home-counter-five").each(function () {
		$(this).data('count', parseInt($(this).html(), 10));
		$(this).html('0');
		five($(this));
	});
	$(".icon-detail span.counter.home-counter-six").each(function () {
		$(this).data('count', parseInt($(this).html(), 10));
		$(this).html('0');
		six($(this));
	});
});

// $(function () {
// 	function count($this) {
// 		var current = parseInt($this.html(), 10);
// 		current = current + 50; /* Where 100 is increment */

// 		$this.html(++current);
// 		if (current > $this.data('count')) {
// 			$this.html($this.data('count'));
// 		} else {
// 			setTimeout(function () {
// 				count($this)
// 			}, 1);
// 		}
// 	}

// 	$(".icon-detail span.counter.home-counter-one").each(function () {
// 		$(this).data('count', parseInt($(this).html(), 10));
// 		$(this).html('0');
// 		count($(this));
// 	});

// });
// ---------------------------------------

function changePricingPlanByDuration(planPricing, countryCode) {
	var payValue = $("input[name='showpayment']:checked").val();
	if (payValue && payValue === "payannually") {
		$('.productTimeOfPeriod').val('yearly')
		if (countryCode === 'IN') {
			// $('.planPricePerDuration').text('/YEAR')
			$('#enterpriseMainPrice').text(planPricing['EVALUATE']['yearly'].spInRs)
			$('#teamMainPrice').text(planPricing['MONITOR']['yearly'].spInRs)
			$('#personalMainPrice').text(planPricing['STARTUP']['yearly'].spInRs)
			if (planPricing['EVALUATE']['yearly'].mrpInRs === planPricing['EVALUATE']['yearly'].spInRs)
				$('#enterprisemrpPrice').html('<div class="flexdirectionRow" id="enterprisemrpPrice"><div><p class="startingWith" style="margin-bottom: 16px;"></p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut"> </sup><strike><span style="color:black; font-size:14px"> </span></strike></div></div>')
			else
				$('#enterprisemrpPrice').html('<div class="flexdirectionRow" id="enterprisemrpPrice"><div><p class="startingWith">Starting With</p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut">₹</sup><strike><span style="color:black; font-size:14px">' + planPricing['EVALUATE']['yearly'].mrpInRs + '</span></strike><sub class="currency-cut">*</sub></div></div>')
			if (planPricing['MONITOR']['yearly'].mrpInRs === planPricing['MONITOR']['yearly'].spInRs)
				$('#teammrpPrice').html('<div class="flexdirectionRow" id="teammrpPrice"><div><p class="startingWith" style="margin-bottom: 16px;"></p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut"> </sup><strike><span style="color:black; font-size:14px"> </span></strike></div></div>')
			else
				$('#teammrpPrice').html('<div class="flexdirectionRow" id="teammrpPrice"><div><p class="startingWith">Starting With</p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut">₹</sup><strike><span style="color:black; font-size:14px">' + planPricing['MONITOR']['yearly'].mrpInRs + '</span></strike><sub class="currency-cut">*</sub></div></div>')
			if (planPricing['STARTUP']['yearly'].mrpInRs === planPricing['STARTUP']['yearly'].spInRs)
				$('#personalmrpPrice').html('<div class="flexdirectionRow" id="personalmrpPrice"><div><p class="startingWith" style="margin-bottom: 16px;"></p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut"> </sup><strike><span style="color:black; font-size:14px"> </span></strike></div></div>')
			else
				$('#personalmrpPrice').html('<div class="flexdirectionRow" id="personalmrpPrice"><div><p class="startingWith">Starting With</p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut">₹</sup><strike><span style="color:black; font-size:14px">' + planPricing['STARTUP']['yearly'].mrpInRs + '</span></strike><sub class="currency-cut">*</sub></div></div>')

		} else {
			$('#enterpriseMainPrice').text(planPricing['EVALUATE']['yearly'].spInDollar)
			$('#teamMainPrice').text(planPricing['MONITOR']['yearly'].spInDollar)
			$('#personalMainPrice').text(planPricing['STARTUP']['yearly'].spInDollar)
			if (planPricing['EVALUATE']['yearly'].mrpInDollar === planPricing['EVALUATE']['yearly'].spInDollar)
				$('#enterprisemrpPrice').html('<div class="flexdirectionRow" id="enterprisemrpPrice"><div><p class="startingWith" style="margin-bottom: 16px;"></p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut"> </sup><strike><span style="color:black; font-size:14px"> </span></strike></div></div>')
			else
				$('#enterprisemrpPrice').html('<div class="flexdirectionRow" id="enterprisemrpPrice"><div><p class="startingWith">Starting With</p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut">$</sup><strike><span style="color:black; font-size:14px">' + planPricing['EVALUATE']['yearly'].mrpInDollar + '</span></strike><sub class="currency-cut">*</sub></div></div>')
			if (planPricing['MONITOR']['yearly'].mrpInDollar === planPricing['MONITOR']['yearly'].spInDollar)
				$('#teammrpPrice').html('<div class="flexdirectionRow" id="teammrpPrice"><div><p class="startingWith" style="margin-bottom: 16px;"></p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut"> </sup><strike><span style="color:black; font-size:14px"> </span></strike></div></div>')
			else
				$('#teammrpPrice').html('<div class="flexdirectionRow" id="teammrpPrice"><div><p class="startingWith">Starting With</p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut">$</sup><strike><span style="color:black; font-size:14px">' + planPricing['MONITOR']['yearly'].mrpInDollar + '</span></strike><sub class="currency-cut">*</sub></div></div>')
			if (planPricing['STARTUP']['yearly'].mrpInDollar === planPricing['STARTUP']['yearly'].spInDollar)
				$('#personalmrpPrice').html('<div class="flexdirectionRow" id="personalmrpPrice"><div><p class="startingWith" style="margin-bottom: 16px;"></p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut"> </sup><strike><span style="color:black; font-size:14px"> </span></strike></div></div>')
			else
				$('#personalmrpPrice').html('<div class="flexdirectionRow" id="personalmrpPrice"><div><p class="startingWith">Starting With</p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut">$</sup><strike><span style="color:black; font-size:14px">' + planPricing['STARTUP']['yearly'].mrpInDollar + '</span></strike><sub class="currency-cut">*</sub></div></div>')

		}

	}
	if (payValue && payValue === "paymonthly") {
		$('.productTimeOfPeriod').val('monthly')
		if (countryCode === 'IN') {
			$('#enterpriseMainPrice').text(planPricing['EVALUATE']['monthly'].spInRs)
			$('#teamMainPrice').text(planPricing['MONITOR']['monthly'].spInRs)
			$('#personalMainPrice').text(planPricing['STARTUP']['monthly'].spInRs)
			if (planPricing['EVALUATE']['monthly'].mrpInRs === planPricing['EVALUATE']['monthly'].spInRs)
				$('#enterprisemrpPrice').html('<div class="flexdirectionRow" id="enterprisemrpPrice"><div><p class="startingWith" style="margin-bottom: 16px;"></p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut"> </sup><strike><span style="color:black; font-size:14px"> </span></strike></div></div>')
			else
				$('#enterprisemrpPrice').html('<div class="flexdirectionRow" id="enterprisemrpPrice"><div><p class="startingWith">Starting With</p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut">₹</sup><strike><span style="color:black; font-size:14px">' + planPricing['EVALUATE']['yearly'].mrpInRs + '</span></strike><sub class="currency-cut">*</sub></div></div>')

			if (planPricing['MONITOR']['monthly'].mrpInRs === planPricing['MONITOR']['monthly'].spInRs)
				$('#teammrpPrice').html('<div class="flexdirectionRow" id="teammrpPrice"><div><p class="startingWith" style="margin-bottom: 16px;"></p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut"> </sup><strike><span style="color:black; font-size:14px"> </span></strike></div></div>')

			else
				$('#teammrpPrice').html('<div class="flexdirectionRow" id="teammrpPrice"><div><p class="startingWith">Starting With</p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut">₹</sup><strike><span style="color:black; font-size:14px">' + planPricing['MONITOR']['yearly'].mrpInRs + '</span></strike><sub class="currency-cut">*</sub></div></div>')

			if (planPricing['STARTUP']['monthly'].mrpInRs === planPricing['STARTUP']['monthly'].spInRs)
				$('#personalmrpPrice').html('<div class="flexdirectionRow" id="personalmrpPrice"><div><p class="startingWith" style="margin-bottom: 16px;"></p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut"> </sup><strike><span style="color:black; font-size:14px"> </span></strike></div></div>')
			else
				$('#personalmrpPrice').html('<div class="flexdirectionRow" id="personalmrpPrice"><div><p class="startingWith">Starting With</p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut">₹</sup><strike><span style="color:black; font-size:14px">' + planPricing['STARTUP']['yearly'].mrpInRs + '</span></strike><sub class="currency-cut">*</sub></div></div>')

		} else {
			$('#enterpriseMainPrice').text(planPricing['EVALUATE']['monthly'].spInDollar)
			$('#teamMainPrice').text(planPricing['MONITOR']['monthly'].spInDollar)
			$('#personalMainPrice').text(planPricing['STARTUP']['monthly'].spInDollar)
			if (planPricing['EVALUATE']['monthly'].mrpInDollar === planPricing['EVALUATE']['monthly'].spInDollar)
				$('#enterprisemrpPrice').html('<div class="flexdirectionRow" id="enterprisemrpPrice"><div><p class="startingWith" style="margin-bottom: 16px;"></p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut"> </sup><strike><span style="color:black; font-size:14px"> </span></strike></div></div>')
			else
				$('#enterprisemrpPrice').html('<div class="flexdirectionRow" id="enterprisemrpPrice"><div><p class="startingWith">Starting With</p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut">$</sup><strike><span style="color:black; font-size:14px">' + planPricing['EVALUATE']['monthly'].mrpInDollar + '</span></strike><sub class="currency-cut">*</sub></div></div>')
			if (planPricing['MONITOR']['monthly'].mrpInDollar === planPricing['MONITOR']['monthly'].spInDollar)
				$('#teammrpPrice').html('<div class="flexdirectionRow" id="teammrpPrice"><div><p class="startingWith" style="margin-bottom: 16px;"></p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut"> </sup><strike><span style="color:black; font-size:14px"> </span></strike></div></div>')
			else
				$('#teammrpPrice').html('<div class="flexdirectionRow" id="teammrpPrice"><div><p class="startingWith">Starting With</p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut">$</sup><strike><span style="color:black; font-size:14px">' + planPricing['MONITOR']['monthly'].mrpInDollar + '</span></strike><sub class="currency-cut">*</sub></div></div>')
			if (planPricing['STARTUP']['monthly'].mrpInDollar === planPricing['STARTUP']['monthly'].spInDollar)
				$('#personalmrpPrice').html('<div class="flexdirectionRow" id="personalmrpPrice"><div><p class="startingWith" style="margin-bottom: 16px;"></p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut"> </sup><strike><span style="color:black; font-size:14px"> </span></strike></div></div>')
			else
				$('#personalmrpPrice').html('<div class="flexdirectionRow" id="personalmrpPrice"><div><p class="startingWith">Starting With</p></div><div class="price" style="line-height:0px; padding:0 5px;"><sup class="currency-cut">$</sup><strike><span style="color:black; font-size:14px">' + planPricing['STARTUP']['monthly'].mrpInDollar + '</span></strike><sub class="currency-cut">*</sub></div></div>')

		}

	}

}


$(document).ready(function () {
	$(".call-to-action").on("click", function () {
		$(this).text("Processing...")
	})
})

$(document).ready(function () {
	$("#influencerLink").keypress(function (e) {
		key = e.which;
		if (key == 13)  // the enter key code
		{
			checkforEmptyUserName()
		}
	});

	var frm = $('#webinarForm');
	frm.submit(function (e) {
		e.preventDefault();
		var name = $("#webinar-name").val();
		var emailId = $("#webinar-emailId").val()
		var companyName = $("#webinar-companyName").val()
		var jobTitle = $("#webinar-jobTitle").val()
		var webinarNumber = $("#webinar-webinarNumber").val()
		// var webinar = $("#webinarName").val()
		var data = {
			name: name,
			emailId: emailId,
			companyName: companyName,
			jobTitle: jobTitle,
			webinarNumber: webinarNumber,
		}
		if (emailId != "" && name != "") {
			$.ajax({
				type: frm.attr('method'),
				url: frm.attr('action'),
				data: data,
				success: function (responseText) {
					console.log(responseText)
					var response = responseText["message"];
					var status = responseText["status"];
					if (status == "SUCCESS") {
						webinarRegistrationNotify(emailId)
						// $("#webinarForm").attr("style", "display:none")
						$("#webinarAcknowledgement").html("Mail sent successfully")
						$("#webinarAcknowledgement").css("color", "green")
						$("#webinarRegistrationBtn").prop('disabled', true);
					} else {
						$("#webinarAcknowledgement").html("There is some error. Please contact support@rankmeonline.com")
						$("#webinarAcknowledgement").css("color", "red")
					}
					// return true;
				},
				error: function (res) {
					var response = res["message"]
					$("#webinarAcknowledgement").html(response)
					$("#webinarAcknowledgement").css("color", "red")
					// return false;
				},
			});
		}
	})
})

function cookiePolicy() {
	if (typeof (Storage) !== "undefined") {
		if (localStorage.getItem("cookie-policy") == null) {
			$("#cookiescript_injected").hide();
			$('#cookiescript_injected').delay(5000).fadeIn(500); // or fade, css display however you'd like.
			// 	setTimeout(function(){
			// 		$('#cookiescript_injected').fade();// or fade, css display however you'd like.
			//  }, 5000);
			$("#cookiescript_readmore").click(function () {
				document.location.href = "/privacy-policy"
			})
		} else {
			$("#cookiescript_injected").hide();
		}
	} else {
		// Sorry! No Web Storage support..
	}
	$("#cookiescript_accept").click(function () {
		if (typeof (Storage) !== "undefined") {
			localStorage.setItem("cookie-policy", "ACCEPT");
			$("#cookiescript_injected").hide();
		} else {
			// Sorry! No Web Storage support..
		}
	})
	$("#cookiescript_reject").click(function () {
		if (typeof (Storage) !== "undefined") {
			localStorage.setItem("cookie-policy", "REJECT");
			$("#cookiescript_injected").hide();
		} else {
			// Sorry! No Web Storage support..
		}
	})
}





// function sendIndustryRepost(event) {
// 	event.preventDefault();

$(document).ready(function () {
	var frm = $('#industryForm');
	frm.submit(function (e) {
		e.preventDefault();
		var emailId = $("#industry-emailId").val()
		var companyName = $("#industry-companyName").val()
		var industry = $("#industryName").val()
		var data = {
			emailId: emailId,
			companyName: companyName,
			industry: industry
		}
		if (emailId != "") {
			$.ajax({
				type: frm.attr('method'),
				url: frm.attr('action'),
				data: data,
				success: function (responseText) {
					console.log(responseText)
					var response = responseText["message"];
					var status = responseText["status"];
					if (status == "SUCCESS") {
						requestForIndustryReport(emailId)
						// $("#industryForm").attr("style", "display:none")
						$("#industryAcknowledgement").html("Report mailed successfully")
						$("#industryAcknowledgement").css("color", "green")
						$("#industryReportSendBtn").prop('disabled', true);
					} else {
						$("#industryAcknowledgement").html("There is some error. Please contact support@rankmeonline.com")
						$("#industryAcknowledgement").css("color", "red")
					}
					return true;
				},
				error: function (res) {
					$("#industryAcknowledgement").html(response)
					$("#industryAcknowledgement").css("color", "red")
					return false;
				},
			});
		}
	})
})

function startFreeTrail() {
	ga('send', {
		hitType: 'event',
		eventCategory: 'Trial',
		eventAction: 'Click',
		eventLabel: 'TrialInterested'
	});

}


// $("#industryForm").ajaxSubmit({
// 	url: "/mail-industry-report",
// 	method: "POST",
// 	data: data,
// 	success: function (responseText) {
// 		console.log(responseText)
// 		var response = responseText["message"];
// 		var status = responseText["status"];
// 		if (status == "SUCCESS") {
// 			requestForDemo()
// 			$("#industryAcknowledgement").html("Report is mailed successfully")
// 			$("#industryReportSendBtn").prop('disabled', true);
// 			$('#industryForm input').change(function () {
// 				if ($(this).val != '') {
// 					$('#industryReportSendBtn').prop('disabled', false);
// 				}
// 			});
// 		} else {
// 			$(errorClass).html(response)
// 			$(errorClass).css("color", "red")
// 		}
// 		return true;
// 	},
// 	error: function (res) {
// 		$("#industryAcknowledgement").html("There is some error. Please contact support@rankmeonline.com")
// 		$("#industryAcknowledgement").css("color", "red")
// 		return false;
// 	},
// })
// }
// }

;
(function () {

	'use strict';

	function clickHandler(event) {
		if (event.ctrlKey || event.metaKey) {
			//ctrlKey to detect ctrl + click
			//metaKey to detect command + click on MacOS
			return true
		} else {
			return false
		}
	};



	$(document).ready(function () {
		$("a[href]").on('click', function () {
			var cntrlIsPressed = clickHandler(event)
			if (!cntrlIsPressed) {
				var checkhash = $(this).attr("href")
				var checktarget = $(this).is("[target]")
				var checkslide = $(this).is("[data-slide]")
				if (checkhash != '#' && checkhash != "javascript:void(0);" && checkhash != "" && checktarget == false && checkslide == false) {
					showLoaderOnClickWebsite()
				}
			}
		})
	})


	function showLoaderOnClickWebsite() {
		$("#allPageLoaderWebsite").html("<img style='height: 50px;width: 50px;z-index:1051;position: fixed;top: 40%;left: 45%;' src = '/images/loader-icon.gif' alt='Loading' />")
	}

	function hideLoaderOnClickWebsite() {
		$("#allPageLoaderWebsite").html("")
	}
	// iPad and iPod detection	
	var isiPad = function () {
		return (navigator.platform.indexOf("iPad") != -1);
	};

	var isiPhone = function () {
		return (
			(navigator.platform.indexOf("iPhone") != -1) ||
			(navigator.platform.indexOf("iPod") != -1)
		);
	};

	// Main Menu Superfish
	var mainMenu = function () {

		$('#fh5co-primary-menu').superfish({
			delay: 0,
			animation: {
				opacity: 'show'
			},
			speed: 'fast',
			cssArrows: true,
			disableHI: true
		});

	};

	// Parallax
	/*var parallax = function () {
		$(window).stellar();
	};*/


	// Offcanvas and cloning of the main menu
	var offcanvas = function () {

		var $clone = $('#fh5co-menu-wrap').clone();
		$clone.attr({
			'id': 'offcanvas-menu'
		});
		$clone.find('> ul').attr({
			'class': '',
			'id': ''
		});

		$('#fh5co-page').prepend($clone);
		$('#offcanvas-menu').addClass("offcanvas-menu-hidden")

		// click the burger
		$('.js-fh5co-nav-toggle').on('click', function () {

			if ($('body').hasClass('fh5co-offcanvas')) {
				$('body').removeClass('fh5co-offcanvas');
				$('#offcanvas-menu').addClass("offcanvas-menu-hidden")
				// $("#offcanvas-menu").attr("style", "display:none")
			} else {
				$('body').addClass('fh5co-offcanvas');
				$('#offcanvas-menu').removeClass("offcanvas-menu-hidden")
				// $("#offcanvas-menu").attr("style", "display")
			}

			// $('body').toggleClass('fh5co-offcanvas');

		});

		$('#offcanvas-menu').css('height', $(window).height());

		$(window).resize(function () {
			var w = $(window);


			$('#offcanvas-menu').css('height', w.height());

			if (w.width() > 769) {
				if ($('body').hasClass('fh5co-offcanvas')) {
					$('body').removeClass('fh5co-offcanvas');
				}
			}

		});

	}



	// Click outside of the Mobile Menu
	var mobileMenuOutsideClick = function () {
		$(document).click(function (e) {
			var container = $("#offcanvas-menu, .js-fh5co-nav-toggle");
			if (!container.is(e.target) && container.has(e.target).length === 0) {
				if ($('body').hasClass('fh5co-offcanvas')) {
					$('body').removeClass('fh5co-offcanvas');
				}
			}
		});
	};


	// Animations

	var contentWayPoint = function () {
		var i = 0;
		$('.animate-box').waypoint(function (direction) {

			if (direction === 'down' && !$(this.element).hasClass('animated')) {

				i++;

				$(this.element).addClass('item-animate');
				setTimeout(function () {

					$('body .animate-box.item-animate').each(function (k) {
						var el = $(this);
						setTimeout(function () {
							el.addClass('fadeInUp animated');
							el.removeClass('item-animate');
						}, k * 200, 'easeInOutExpo');
					});

				}, 100);

			}

		}, {
			offset: '85%'
		});
	};


	var scheduleTab = function () {
		$('.schedule-container').css('height', $('.schedule-content.active').outerHeight());

		$(window).resize(function () {
			$('.schedule-container').css('height', $('.schedule-content.active').outerHeight());
		});

		$('.schedule a').on('click', function (event) {

			event.preventDefault();

			var $this = $(this),
				sched = $this.data('sched');

			$('.schedule a').removeClass('active');
			$this.addClass('active');
			$('.schedule-content').removeClass('active');

			$('.schedule-content[data-day="' + sched + '"]').addClass('active');

		});
	};

	// Document on load.
	$(function () {
		mainMenu();
		//parallax();
		offcanvas();
		mobileMenuOutsideClick();
		contentWayPoint();
		scheduleTab();
	});


}());
$(document).ready(function () {


	var pageUrl = document.location.href;
	if (pageUrl.indexOf("?") == -1) {
		pageUrl = pageUrl + "?";
	}
	var params = splitIntoParams(pageUrl)
	if (params !== null && params.length > 0) {
		for (var i = 0; i < params.length; i++) {
			if (params[i].param == "alertMessage") {
				alert(params[i].value)
			}
		}
	}
})

function splitIntoParams(pageUrl) {

	var paramString = pageUrl.split("?");
	var paramArray = [];
	if (paramString.length > 1) {
		var params = paramString[1].split("&");
		paramArray = params.filter(function (item) {
			if (item != "") {
				return true;
			}
			return false;
		}).map(function (item) {
			var properties = item.split("=");
			return {
				param: properties[0],
				value: decodeURIComponent(properties[1])
			};
		});
	}
	return paramArray;
}

function requestForDemo() {
	$.notify({
		title: '',
		icon: 'fa fa-check',
		message: "Thank you for joining. Get ready to experience the tech innovation before others!",
	}, {
		type: 'info',
		animate: {
			enter: 'animated fadeInUp',
			exit: 'animated fadeOutRight'
		},
		placement: {
			from: "top",
			align: "right"
		},
		offset: {
			x: -800,
			y: 80
		},
		spacing: 10,
		z_index: 1051000000,

	});
}

function requestForIndustryReport(emailId) {
	$.notify({
		title: '',
		icon: 'fa fa-check',
		message: "Your PDF report is queued and will be mailed to " + emailId + " in a few minutes!",
	}, {
		type: 'info',
		animate: {
			enter: 'animated fadeInUp',
			exit: 'animated fadeOutRight'
		},
		placement: {
			from: "top",
			align: "right"
		},
		offset: {
			x: -800,
			y: 80
		},
		spacing: 10,
		z_index: 1051000000,

	});
}

function webinarRegistrationNotify(emailId) {
	$.notify({
		title: '',
		icon: 'fa fa-check',
		message: "Registration for webinar is successful! You will receive further updates on your email.",
	}, {
		type: 'info',
		animate: {
			enter: 'animated fadeInUp',
			exit: 'animated fadeOutRight'
		},
		placement: {
			from: "top",
			align: "right"
		},
		offset: {
			x: -800,
			y: 80
		},
		spacing: 10,
		z_index: 1051000000,

	});
}

function contactpageformSubmit() {
	var emailId = $("#email").val();
	var email = emailId.trim();
	$("#email").on('click', function () {
		$(".emailerr").html("");
	});
	if (email == "" || email == null) {
		$(".emailerr").html("*Please enter an email address");
	}
	else {
		if (email.indexOf("@") > -1 && /[a-z]/.test(email) && (!/[\s]/.test(email))) {
			$.ajax({
				type: 'GET',
				url: '/captcha-value',
				success: function (res) {
					console.log(res);
					$('#captcha-image').html(res.captcha);
					$("#formDetails").val($(".js-captcha").attr("id"));
					$('#captchaModel').modal('show');
				}
			});
		}
		else {
			$(".emailerr").html("*Please enter a valid email address");
		}
	}
}

function contactUsFooterFormOnSubmit() {
	var phone = $(".js-phone").val();
	if (phone != undefined) {
		$(".js-phone").on('click', function () {
			$(".phonerr").html("");
		});
		if (phone == "" || phone == null) {
			$(".phonerr").html("*Please enter a mobile number");
		} else {
			if (phone.length == 10 && /^\d+$/.test(phone)) {
				$.ajax({
					type: 'GET',
					url: '/captcha-value',
					success: function (res) {
						console.log(res);
						$('#captcha-image').html(res.captcha);
						$('#captchaModel').modal('show');
						$("#formDetails").val($(".footer-form").attr("id"));
						fadeOutModal();
					}
				});
			} else {
				$(".phonerr").html("*Please enter a valid mobile number");
			}
		}
	}
}


function submitform() {
	var emailId = $("#email").val();
	if (emailId != undefined) {
		var email = emailId.trim();
		var emailValid = false;
		$("#email").on('click', function () {
			$(".emailerr").html("");
		});
		if (email == "" || email == null) {
			$(".emailerr").html("*Please enter an email address");
		} else {
			if (email.indexOf("@") > -1 && /[a-z]/.test(email) && (!/[\s]/.test(email))) {
				emailValid = true;
			} else {
				$(".emailerr").html("*Please enter a valid email address");
			}
		}
	}
	var phone = $("#phone").val();
	if (phone != undefined) {
		$("#phone").on('click', function () {
			$(".phonerr").html("");
		});
		var phoneValid = false;
		if (phone == "" || phone == null) {
			$(".phonerr").html("*Please enter a mobile number");
		} else {
			//Regex for US Phone no.s used from https://stackoverflow.com/a/18029630
			var regexUSPhone = RegExp(/\+(9[976]\d | 8[987530]\d | 6[987]\d | 5[90]\d | 42\d | 3[875]\d | 2[98654321]\d | 9[8543210] | 8[6421] | 6[6543210] | 5[87654321] | 4[987654310] | 3[9643210] | 2[70] | 7 | 1) \W *\d\W *\d\W *\d\W *\d\W *\d\W *\d\W *\d\W *\d\W * (\d{ 1, 2 }) $'/);

			if ((phone.length == 10 && /^\d+$/.test(phone)) || regexUSPhone.test(phone)) {
				phoneValid = true;
			} else {
				$(".phonerr").html("*Please enter a valid phone number");
			}
		}
	}
	if (phoneValid == true && emailValid == true) {
		event.preventDefault();
		$.ajax({
			type: 'GET',
			url: '/captcha-value',
			success: function (res) {
				console.log(res);
				$('#captcha-image').html(res.captcha);
				$('#captchaModel').modal('show');
				$('#captchaModel').appendTo('body');
				$("#formDetails").val($(".demo-form").attr("id"));
			}
		});

	}
}

$(document).ready(function () {
	$(".filter-make-infl").on("change", function (e) {
		var val = $(e.target).val();
		console.log(val)
		if (val == "INSTAGRAM") {
			$('#find-inflsearch input').val("https://www.instagram.com/")
		}
		else if (val == "TIKTOK") {
			$('#find-inflsearch input').val("https://www.tiktok.com/@")
		}
		else if (val == "LINKEDIN") {
			$('#find-inflsearch input').val("https://www.linkedin.com/company/")
		}
		else if (val == "YOUTUBE") {
			$('#find-inflsearch input').val("https://www.youtube.com/channel/")
		}
		else if (val == "FACEBOOK") {
			$('#find-inflsearch input').val("https://www.facebook.com/")
		}
		else if (val == "TWITTER") {
			$('#find-inflsearch input').val("https://twitter.com/")
		}
		else {
			$('#find-inflsearch input').val("")
		}

	})
})


function showAllInfluencer() {
	$("#nilUserError").text('')
	$("#showAllInfluencerDiv").toggleClass("showAllFilters").toggleClass("hideAllFilters")
	var selectedSource = $('#userSelectedSource').attr("alt")
	$('#source-' + selectedSource).toggleClass("hideAllFilters")
}

function activateInfluencerFilter(filterName) {

	var selectedSource = $('#userSelectedSource').attr("alt")
	$('#source-' + selectedSource).toggleClass("hideAllFilters")
	if (filterName == "INSTAGRAM") {
		$('#find-inflsearch input').val("https://www.instagram.com/")
		$('#userSelectedSource').attr("src", "images/report/instagram.png");
	}
	else if (filterName == "TIKTOK") {
		$('#find-inflsearch input').val("https://www.tiktok.com/@")
		$('#userSelectedSource').attr("src", "images/report/tiktok.png");
	}
	else if (filterName == "LINKEDIN") {
		$('#find-inflsearch input').val("https://www.linkedin.com/company/")
		$('#userSelectedSource').attr("src", "images/report/linkedin.png");
	}
	else if (filterName == "YOUTUBE") {
		$('#find-inflsearch input').val("https://www.youtube.com/channel/")
		$('#userSelectedSource').attr("src", "images/report/youtube.png");
	}
	else if (filterName == "FACEBOOK") {
		$('#find-inflsearch input').val("https://www.facebook.com/")
		$('#userSelectedSource').attr("src", "images/report/facebook.png");
	}
	else if (filterName == "TWITTER") {
		$('#find-inflsearch input').val("https://www.twitter.com/")
		$('#userSelectedSource').attr("src", "images/report/twitter.png");
	}
	else {
		$('#find-inflsearch input').val("")
	}
	$('#userSelectedSource').attr("alt", filterName)
	$("#showAllInfluencerDiv").toggleClass("showAllFilters").toggleClass("hideAllFilters")

}




function checkforEmptyUserName() {
	$("#nilUserError").text('')
	var textValue = $('#find-inflsearch input').val()
	var username = textValue.split('/')[3]
	username = username.replace('@', '')
	var getSource = textValue.split('https://')[1]
	getSource = getSource.replace("www.", '')
	var source = getSource.split('.com')[0].toUpperCase()
	if (source == "YOUTUBE") {
		username = textValue.split('/')[4]
	}
	if (username == '') {
		if (source == "YOUTUBE") {
			$("#nilUserError").text("Please fill channel-id for Youtube")
		}
		else if (source == "INSTAGRAM") {
			$("#nilUserError").text("Please fill username for Instagram")
		}
		else if (source == "TWITTER") {
			$("#nilUserError").text("Please fill username for Twitter")
		}
		else if (source == "FACEBOOK") {
			$("#nilUserError").text("Please fill username for Facebook")
		}
		else if (source == "TIKTOK") {
			$("#nilUserError").text("Please fill username for Tiktok")
		}
		return;
	} else {
		window.open('/find-new-influencer?influencerLink=' + textValue, '_blank')
		// document.location = "/find-new-influencer?influencerLink=" + textValue
	}
}